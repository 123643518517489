html {
  scroll-behavior: smooth;
  --blackColor: #1C1C1E;
  --darkBlackColor: #161616;
  --whiteColor: #fff;
  --darkGolderColor: #665837;
  --layoutTheme: #191919;
  --layoutSiderMenu: #23211e;
  --tomatoRed: #ff4444;
  --parrotGreen: #00D539;

  --defaultThemeColor: #AF9451;
  --defaultFontsFamilyPopins: "Poppins", sans-serif;
  --defaultColorOne: #582A26;
  --defaultColorTwo: #AF9451;
  --defaultGradientColor: linear-gradient(to left, var(--defaultColorTwo), var(--defaultColorOne));
  --defaultGradientColorReverse: linear-gradient(to left, var(--defaultColorOne), var(--defaultColorTwo));
}

*,
body {
  box-sizing: border-box;
  font-family: var(--defaultFontsFamilyPopins) !important;
}

p{
  line-height: 1.8;
  font-weight: 500;
}

/* Side Scroller */
::-webkit-scrollbar {
  height: 9px;
  width: 8px
}

::-webkit-scrollbar-track {
  background: #d9dcd7
}

::-webkit-scrollbar-thumb {
  background: var(--blackColor);
  border-radius: 3px
}

::-webkit-scrollbar-thumb:hover {
  background: #337ec3
}

/* =========================================================== ANTD GLOBAL =========================================================== */
.ant-layout {
  font-family: var(--defaultFontsFamilyPopins) !important;
  font-weight: 600;
}

li.ant-menu-item.ant-menu-item-only-child {
  /* background-color: #f1f1f1; */
  background-color: rgba(223, 70, 62, 0.062);
  border-radius: 0 !important;
  margin: 0 0 3px !important;
  padding: 1.5rem;
}

.headingStyle {
  opacity: 0;
  animation: fadeIn 3.5s ease-out forwards;
  -webkit-animation: fadeIn 3.5s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
  background: var(--whiteColor);
}


li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  color: var(--whiteColor) !important;
  background: var(--defaultGradientColorReverse);
  font-weight: bolder !important;
  border-left: 7px solid var(--defaultColorTwo);
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .sideIcon {
  color: var(--whiteColor) !important;
}

.sideIcon {
  color: var(--defaultColorOne);
  font-size: 20px;
}

.ant-layout .ant-layout-sider {
  /* background: var(--defaultGradientColor); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 25px;
  list-style-position: inside;
  list-style-type: disc;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ant-dropdown-menu-item {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu .ant-menu-title-content {
  transition: color 0.3s;
  align-items: center;
  display: flex;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 1px dashed var(--defaultGradientColor);
}

.ant-table-thead .ant-table-cell {
  background-color: var(--defaultColorTwo) !important;
  color: var(--blackColor) !important;
}

.jodit-container:not(.jodit_inline) .jodit-workplace,
.jodit-container:not(.jodit_inline) {
  border-radius: 3px;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid var(--defaultGradientColor) !important;
  border-radius: 3px;
}

.jodit-status-bar {
  display: none !important;
}

.ant-breadcrumb-separator {
  font-size: 1.2rem;
}

.ant-input::placeholder {
  color: lightgray !important;
}

span.ant-select-selection-item {
  display: flex;
  text-align: center;
  align-items: center;
  /* color: var(--whiteColor); */
}


.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultThemeColor);
}

.ant-select-selector {
  border: 1px solid var(--defaultThemeColor) !important;
  min-height: 2.5rem;
}

.ant-select-single {
  min-height: 2.5rem;
  text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 8rem !important;
  height: 8rem !important;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  border: 1px dashed var(--defaultThemeColor);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  width: 8rem !important;
  height: 8rem !important;
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled):hover {
  border: 1px solid var(--defaultThemeColor) !important;
}

.ant-picker-outlined:hover {
  border: 1px solid var(--defaultThemeColor) !important;
}


.ant-switch {
  background-color: var(--defaultColorTwo) !important;
}

.ant-table-wrapper .ant-table-cell-fix-left {
  background: inherit;
}

.ant-switch.ant-switch-checked {
  background: var(--defaultColorOne) !important;
}

.ant-picker.ant-picker-range {
  width: 100%;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: var(--whiteColor);
  background: var(--defaultGradientColor);
  border-color: var(--defaultGradientColor);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-divider-horizontal {
  margin: 24px 0;
  background: var(--defaultGradientColor);
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 4px;
  list-style-type: none;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  margin-bottom: 3px !important;
  color: var(--blackColor) !important;
  font-size: 1rem;
  background: var(--defaultColorTwo) !important;
}

.ant-dropdown {
  min-width: 132.507px !important;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 14px;
  line-height: 30px;
  background: transparent !important;
  border: transparent !important;
}

.ant-tooltip.css-dev-only-do-not-override-1rqnfsa.ant-tooltip-placement-top {
  display: none;
}

.highcharts-button {
  display: none
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: var(--defaultGradientColor);
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--defaultGradientColor) !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--defaultGradientColor) !important;
}


.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--defaultThemeColor) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--defaultGradientColor) !important;
  border-color: var(--defaultGradientColor);
}

.ant-checkbox .ant-checkbox-inner {
  border: 1px solid var(--defaultGradientColor);
}

.award.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {

  border: none !important;
}

/* Table */
.ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper .ant-table-thead>tr>td {
  border-bottom: none !important;
}

.ant-table-wrapper .ant-table {
  background: transparent;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

/* Chart */
text.highcharts-credits {
  display: none;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
  border-bottom: transparent !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

g.highcharts-legend-item.highcharts-area-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

g.highcharts-legend-item.highcharts-map-series.highcharts-color-undefined.highcharts-series-0 {
  display: none;
}

.ant-layout .ant-layout-header {
  background-color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
}

.ant-steps-item-active .ant-steps-item-title {
  color: var(--defaultThemeColor) !important;
}

/* ANIMATION */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
  -webkit-transition: opacity 700ms ease-in;
  -moz-transition: opacity 700ms ease-in;
  -ms-transition: opacity 700ms ease-in;
  -o-transition: opacity 700ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 700ms ease-out;
  -webkit-transition: opacity 700ms ease-out;
  -moz-transition: opacity 700ms ease-out;
  -ms-transition: opacity 700ms ease-out;
  -o-transition: opacity 700ms ease-out;
}

.input__ {
  background-color: #fbfcff;
  background-color: var(--defaultwhitecolor);
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
  height: 3rem;
}

.link_style {
  color: #000;
  text-decoration: none;
}

.avtar {
  border: 1px solid var(--defaultColorTwo);
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
  font-style: italic;
  height: 41px;
  padding: 1.5rem;
  align-items: center;
  display: flex;
}

.toggling_sider {
  box-shadow: 0 5px 15px rgba(0, 0, 0, .35);
  min-width: 250px !important;
}

.color_breadcrumb {
  color: var(--defaultColorOne) !important;
}

.commonButton {
  background: var(--defaultGradientColor);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 16rem;
  text-transform: uppercase;
}

.commonButtonDelete {
  background: var(--defaultGradientColor);
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 6rem;
  text-transform: uppercase;
}

.commonButtonEdit {
  border: 2px solid var(--defaultColorOne);
  color: var(--defaultColorOne);
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 6rem;
  text-transform: uppercase;
}

.changePassword {
  color: var(--defaultColorOne);
  border: 3px solid var(--defaultColorOne);
  font-size: 1rem;
  font-weight: 600;
  height: 3rem;
  min-width: 15rem;
  outline: auto;
  text-transform: uppercase;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* align-items: center; */
}

.searchField>input {
  background-color: transparent !important;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.searchField {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.delete_button {
  cursor: pointer;
  color: var(--defaultColorOne);
  font-size: 1.3rem;
}

.view_button {
  cursor: pointer;
  color: var(--defaultColorTwo);
  font-size: 1.3rem;
}

.commonBoxShadow {
  box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
}

.customSiderWidth {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  flex: 0 0 250px !important;
}

/* Dahboard animation */
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  margin: 0 15px 30px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #fff;
  color: #000;
  overflow: hidden;
  position: relative;
}

.ant-image .ant-image-img {
  width: auto !important;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}

.ag-courses-item_title {
  min-height: 70px;
  margin: 0 0 25px;
  overflow: hidden;
  font-weight: bold;
  font-size: 30px;
  color: #000;
  z-index: 2;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}

.ag-courses-item_date-box {
  font-size: 18px;
  color: #000;
  z-index: 2;
  position: relative;
}

.ag-courses-item_date {
  font-weight: bold;
  color: #000;
  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}

.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background: var(--defaultGradientColor);
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;
  border-radius: 50%;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

@media screen and (max-width: 992px) {
  form.ant-form.ant-form-vertical.css-dev-only-do-not-override-1rqnfsa {
    width: 90%;
  }

  .ant-layout-sider {
    display: none !important;
  }

  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    padding-left: 11px !important;
  }

  li.ant-menu-item.ant-menu-item-only-child {
    padding: 0 0 0 13px !important;
  }

  .customSiderWidth {
    width: 65px !important;
    min-width: 65px !important;
    max-width: 65px !important;
    flex: 0 0 65px !important;
  }

  .commonButton {
    min-width: 10rem !important;
  }

}

.ant-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  display: block;
  width: 30px;
  height: 30px;
  direction: ltr;
  background-color: lightgray;
}

.ant-upload-list-picture-card{
  gap: 38px!important;
}

.indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  margin: 0 2px;
}

.indicator-home {
  background-color: #5d3028; 
}

.indicator-trending {
  background-color: #af9451; 
}

.indicator-recommended {
  background-color: #a50505;
}

.jodit-placeholder{
  opacity: 0;
}